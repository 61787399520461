import { RawVariantDocument } from './raw-base.models';

export interface RawRecommendationsRequest {
    clientGuid: string;
    indexName?: string;
    landingPageUrl?: string;
    visitId: string;
    visitorId: string;
    renderHTML: boolean;
    widgetUids: Array<{
        widgetGuid: string;
        uniqueid?: string;
    }>;
}

export type RawRecommendationsItemCustomDict = Record<string, string | Array<string>> & RawVariantDocument;

export interface RawRecommendationsItem extends Record<string, any> {
    customDict: RawRecommendationsItemCustomDict;
    id: string;
    imageUrl?: string;
    itemName: string;
    url: string;
    price?: string;
    rating?: string;
    salePrice?: string;
    shortDescription?: string;
}

export interface RawRecommendationsWidget {
    isCarousel: boolean;
    isVertical: boolean;
    itemsCount: number;
    recommendationItems?: Array<RawRecommendationsItem>;
    widgetCssClass: string;
    widgetGuid: string;
    widgetName: string;
}

export interface RawRecommendationsResponse {
    isSuccess: boolean;
    requestId: string;
    widgetItems?: Array<RawRecommendationsWidget>;
}
