import { Item } from './base.models';

/**
 * @category Recommendations
 */
export interface RecommendationsRequest {
    widgetId: string;
    itemId?: string;
    url?: string;
}

/**
 * @category Recommendations
 */
export interface RecommendationsItem extends Item {}

/**
 * @category Recommendations
 */
export interface RecommendationsWidget {
    id: string;
    requestId: string;
    title: string;
    carousel: boolean;
    items?: Array<RecommendationsItem>;
}

/**
 * @category Recommendations
 */
export interface RecommendationsResponse {
    requestId: string;
    widgets?: Array<RecommendationsWidget>;
}
