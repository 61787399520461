import { Item, ItemType } from './base.models';

/**
 * @category Content
 */
export interface ContentType {
    id: number;
    campaignId: number;
    type: string;
    title: string;
    trackingEnabled: boolean;
    zone: string;
}

/**
 * @category Content
 */
export interface CustomContent extends ContentType {
    type: 'custom';
    content: string;
}

/**
 * @category Content
 */
export interface CustomWidgetContent extends ContentType {
    type: 'custom-widget';
    data: any;
}

/**
 * @category Content
 */
export interface FeaturedItemsContentItem extends Item {
    pinned: boolean;
    score: number;
    type: ItemType;
}

/**
 * @category Content
 */
export interface FeaturedItemsContent extends ContentType {
    type: 'featured-items';
    items: Array<FeaturedItemsContentItem>;
}

/**
 * @category Content
 */
export interface ImageContent extends ContentType {
    type: 'image';
    image: {
        url: string;
        height?: number;
        width?: number;
        altText?: string;
        title?: string;
    };
    link?: {
        url: string;
        target: string;
    };
}

/**
 * @category Content
 */
export interface PopularQueriesContentItem {
    query: string;
    count: number;
    weight: number;
}

/**
 * @category Content
 */
export interface PopularQueriesContent extends ContentType {
    type: 'popular-queries';
    items: Array<PopularQueriesContentItem>;
}

/**
 * @category Content
 */
export interface ContentZoneItem {
    mobile: ContentType;
    tablet: ContentType;
    desktop: ContentType;
}

/**
 * @category Content
 */
export interface ContentZone {
    name: string;
    items: Array<ContentZoneItem>;
}

/**
 * @category Content
 */
export interface ContentZones {
    [zone: string]: ContentZone;
}
