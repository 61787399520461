/**
 * ## Overriding Component Templates
 *
 * This library was designed to be flexible and allow total customization of the markup used by all components.
 *
 * There are two ways to replace the Handlebars template used by a component:
 *
 * - Pass a template HTML string containing a Handlebars template.
 * - Pass the ID of a template element containing a Handlebars template.
 *
 * *Note: To avoid conflicts with BigCommerce and other systems that Handlebars for server-side rendering, this library also supports an alternate `[[value]]` syntax to work the same as the default Handlerbars `{{value}}` syntax.*
 *
 * ### Passing an HTML string
 *
 * In your HTML file, define the template content directly in the configuration options:
 *
 * ```html
 * <!DOCTYPE html>
 * <html>
 *   <head>
 *     <meta charset="utf-8" />
 *     <title>HawkSearch Handlebars UI</title>
 *     <meta name="viewport" content="width=device-width, initial-scale=1" />
 *     <script type="text/javascript">
 *       var HawkSearch = HawkSearch || {};
 *
 *       HawkSearch.config = {
 *         clientId: "YOUR_CLIENTID_HERE",
 *         components: {
 *           pageSize: {
 *             template: `
 *               <div class='page-size'>
 *                 <select hawksearch-page-size>
 *                   {{#each options}}
 *                     <option value='{{pageSize}}' {{attribute 'selected' selected}}>{{title}}</option>
 *                   {{/each}}
 *                 </select>
 *               </div>`
 *           }
 *         }
 *       };
 *     </script>
 *     <script src="node_modules/@bridgeline-digital/hawksearch-handlebars-ui/dist/handlebars-ui.js" defer ></script>
 *   </head>
 *   <body>
 *     <h1>HawkSearch Handlebars UI</h1>
 *     <hawksearch-search></hawksearch-search>
 *     <hawksearch-results></hawksearch-results>
 *   </body>
 * </html>
 * ```
 *
 * ### Creating a script or template element
 *
 * In your HTML file, add the custom markup to either a `script` element with `type` attribute set to `text/x-handlebars-template` or a `template` element and pass the ID in the configuration options:
 *
 * ```html
 * <!DOCTYPE html>
 * <html>
 *   <head>
 *     <meta charset="utf-8" />
 *     <title>HawkSearch Handlebars UI</title>
 *     <meta name="viewport" content="width=device-width, initial-scale=1" />
 *     <script type="text/javascript">
 *       var HawkSearch = HawkSearch || {};
 *
 *       HawkSearch.config = {
 *         clientId: "f51060e1c38446f0bacdf283390c37e8",
 *         components: {
 *           pageSize: {
 *             template: 'page-size-template'
 *           }
 *         }
 *       };
 *     </script>
 *     <script src="node_modules/@bridgeline-digital/hawksearch-handlebars-ui/dist/handlebars-ui.js" defer ></script>
 *   </head>
 *   <body>
 *     <h1>HawkSearch Handlebars UI</h1>
 *     <hawksearch-search></hawksearch-search>
 *     <hawksearch-results></hawksearch-results>
 *     <script id="page-size-template" type="text/x-handlebars-template">
 *       <div class='page-size'>
 *         <select hawksearch-page-size>
 *           {{#each options}}
 *             <option value='{{pageSize}}' {{attribute 'selected' selected}}>{{title}}</option>
 *           {{/each}}
 *         </select>
 *       </div>
 *     </script>
 *   </body>
 * </html>
 * ```
 *
 * * *Note: `script` elements are preferred over `template` elements as they prevent some browser parsing behavior which can conflict with Handlebars directives in some edge cases.*
 *
 * *Note: This approach tends to be easier to manage than the previous option as it is more compatible with popular HTML editors and templates can potentially be loaded from separate files using Server-side Includes (SSI).*
 *
 * ## Customizing CSS
 * By default, a neutral interface is rendered to get your search instance up and running quickly. However, this library was designed to be customizable and allow developers to use both custom markup and custom CSS.
 *
 * For developers who only wish to tweak the default UI rather than create something from scratch, there are some variables and utility methods defined in the default CSS that are available for use.
 *
 * ### Variables
 * Although all components in this library utilize the Shadow DOM, CSS variables are able to cross this boundary in a way that normal CSS rules are not. The following variables can be customized in the host DOM to easily customize some of the default styles:
 *
 * | Variable | Default Value |
 * | :- | :- |
 * | --hawksearch-container-background--alternate | `var(--color-secondary-light)` |
 * | --hawksearch-container-border | `solid 1px var(--color-secondary)` |
 * | --hawksearch-default-line-height | `1.25` |
 * | --hawksearch-default-font-color | `#333333` |
 * | --hawksearch-default-font-color--inverse | `#ffffff` |
 * | --hawksearch-default-font-family | `Arial, sans-serif` |
 * | --hawksearch-default-font-size | `16px` |
 * | --hawksearch-button-background | `var(--color-accent)` |
 * | --hawksearch-button-border-radius | `5px` |
 * | --hawksearch-button-font-color | `#ffffff` |
 * | --hawksearch-form-element-background | `var(--color-background)` |
 * | --hawksearch-form-element-border | `solid 1px var(--color-secondary)` |
 * | --hawksearch-form-element-border--focus | `solid 1px var(--color-accent)` |
 * | --hawksearch-form-element-border-radius | `0` |
 * | --hawksearch-form-element-placeholder-color | `var(--color-secondary-dark)` |
 * | --hawksearch-margin | `32px` |
 * | --hawksearch-margin-sm | `calc(var(--margin) / 2)` |
 * | --hawksearch-padding | `24px` |
 * | --hawksearch-padding-xs | `calc(var(--padding) / 4)` |
 * | --hawksearch-padding-sm | `calc(var(--padding) / 2)` |
 * | --hawksearch-secondary-font-color | `var(--color-secondary-dark)` |
 *
 * *Note: Internally, all variables omit the hawksearch- prefix. This prefix is used only to override the default values.*
 *
 * ### Breakpoints
 *
 * This library uses responsive design with the following breakpoints defined:
 *
 * | Name | Suffix | Minimum Width |
 * | :- | :- | :- |
 * | | | `0px` |
 * | small | `-sm` | `740px` |
 * | medium | `-md` | `990px` |
 * | large | `-lg` | `1260px` |
 * | extra-large | `-xl` | `1800px` |
 *
 * ### Grid System
 *
 * This library uses a 12-column, responsive grid system. To use this system, all columns must have a `column` class and be the direct children of an element with a `row` class. The number of columns each column elements spans can be controlled with `column--XX` classes.
 *
 * For example, the following will render two equal columns that are stacked until the browser window reaches the `Medium` breakpoint:
 *
 * ```html
 * <div class="row">
 *     <div class="column column--12 column-md--6">Column 1</div>
 *     <div class="column column--12 column-md--6">Column 2</div>
 * </div>
 * ```
 *
 * #### Row Modifiers
 * The following CSS classes can be added to modify the row display:
 *
 * | CSS Class | Description |
 * | :- | :- |
 * | `row--reverse` | This will cause all columns to be rendered in the reverse order that they are defined |
 * | `row--tight` | This will reduce the space between columns to use `var(--padding-xs)` |
 *
 * #### Column Modifiers
 * The following CSS classes can be added to modify the column display:
 *
 * | CSS Class | Description |
 * | :- | :- |
 * | `column--pull-right` | This will force the column to pull to be aligned to the end of the row |
 *
 * ### Utility Classes
 * There are several basic utility classes available to quickly customize the display of elements.
 *
 * *Note: All utility classes support the suffixes defined in Breakpoints. For example, `display-block` and `display-sm-none` are both valid.*
 *
 * * #### Display
 *
 * | CSS Class | Description |
 * | :- | :- |
 * | display-block | This will display an element |
 * | display-none | This will hide an element |
 *
 * #### Flex Vertical
 *
 * | CSS Class | Description |
 * | :- | :- |
 * | flex-vertical-center | This will vertically center the children of an element |
 *
 * #### Flex Gap
 *
 * | CSS Class | Description |
 * | :- | :- |
 * | flex-gap | This will add equal `var(--padding)` spacing between each child element and allow wrapping onto the next row when necessary |
 * | flex-gap--none | This modifier class eliminates the spacing between each child element |
 * | flex-gap--sm | This modifier class reduces the spacing between each element to `var(--padding-sm)` |
 * | flex-gap--xs | This modifier class reduces the spacing between each element to `var(--padding-xs)` |
 *
 * #### Margin
 *
 * | CSS Class | Description |
 * | :- | :- |
 * | margin | This adds bottom margin of `var(--margin)` |
 * | margin-none | This removes bottom margin |
 * | margin-sm | This adds bottom margin of `var(--margin-sm)` |
 *
 * #### Width
 *
 * | CSS Class | Description |
 * | :- | :- |
 * | width-auto | This allows an elements content to determine its width |
 * | width-full | This forces an element’s width to match its parent element |
 *
 * #### Text Align
 *
 * | CSS Class | Description |
 * | :- | :- |
 * | text-align-left | This aligns descendant content to the left |
 * | text-align-center | This aligns descendant content to the center |
 * | text-align-right | This aligns descendant content to the right |
 *
 * @module Components
 */
export * from './base.component';
export * from './content';
export * from './general';
export * from './landing-pages';
export * from './recommendations';
export * from './search';
export * from './variants';
