import { Item } from './base.models';

/**
 * @category Autocomplete
 */
export interface AutocompleteRequest {
    query?: string;
}

/**
 * @category Autocomplete
 */
export interface AutocompleteCategory {
    title: string;
    field: string;
    value: string;
    url: string;
}

/**
 * @category Autocomplete
 */
export interface AutocompleteCategories {
    title: string;
    results: Array<AutocompleteCategory>;
}

/**
 * @category Autocomplete
 */
export interface AutocompleteContentResult {
    id: string;
    title: string;
    url: string;
    pinned: boolean;
    score: number;
    attributes?: Record<string, Array<string>>;
}

/**
 * @category Autocomplete
 */
export interface AutocompleteContent {
    title: string;
    results: Array<AutocompleteContentResult>;
    totalRecords: number;
}

/**
 * @category Autocomplete
 */
export interface AutocompleteQuery {
    query: string;
    url: string;
}

/**
 * @category Autocomplete
 */
export interface AutocompleteQueries {
    title: string;
    results: Array<AutocompleteQuery>;
}

/**
 * @category Autocomplete
 */
export interface AutocompleteProductResult extends Item {
    pinned: boolean;
    score: number;
}

/**
 * @category Autocomplete
 */
export interface AutocompleteProducts {
    title: string;
    results: Array<AutocompleteProductResult>;
    totalRecords: number;
}

/**
 * @category Autocomplete
 */
export interface AutocompleteResponse {
    query?: string;
    categories: AutocompleteCategories;
    content: AutocompleteContent;
    products: AutocompleteProducts;
    queries: AutocompleteQueries;
    contentSuggestedQueries: AutocompleteQueries;
    productSuggestedQueries: AutocompleteQueries;
    totalRecords: number;
    viewAllText: string;
}
