/**
 * @category Base
 */
export type ItemType = 'content' | 'product';

/**
 * @category Base
 */
export interface ItemVariant {
    attributes: Record<string, Array<string>>;
    color?: {
        name?: string;
        hex?: string;
        imageUrl?: string;
    };
    description?: string;
    id: string;
    imageUrl?: string;
    price?: number;
    queryMatch: boolean;
    rating?: number;
    salePrice?: number;
    sku?: string;
    title?: string;
    url?: string;
}

/**
 * @category Base
 */
export interface Variants {
    items: Array<ItemVariant>;
    selectedItem?: ItemVariant;
}

/**
 * @category Base
 */
export interface Item {
    attributes?: Record<string, Array<string>>;
    description?: string;
    id: string;
    imageUrl?: string;
    price?: number;
    rating?: number;
    salePrice?: number;
    selectedVariant?: ItemVariant;
    sku?: string;
    title: string;
    url: string;
    variants?: Array<ItemVariant>;
    visible?: boolean;
}
