import { RawVariantDocument } from './raw-base.models';

export interface RawFacetValue {
    Count: number;
    Label: string;
    Level?: number;
    Value: string;
    RangeEnd: string;
    RangeMax: string;
    RangeMin: string;
    RangeStart: string;
    Selected?: boolean;
    Children?: Array<RawFacetValue>;
}

export interface RawFacetRangeValue {
    AssetFullUrl: string;
    IsNumeric: boolean;
    LBound: string;
    Label: string;
    UBound: string;
    Value: string;
}

// When included as part of PageContent in a content landing page, the API appears to use FeaturedItems instead of Items
export interface RawFeaturedItem extends RawMerchandisingItem {
    FeaturedItems?: Array<RawSearchResultsItem>;
    Items?: Array<RawSearchResultsItem>;
}

export interface RawFeaturedItems {
    Items: Array<RawFeaturedItem>;
}

export interface RawFacetSwatchValue {
    Value: string;
    Color?: string;
    AssetName?: string;
    AssetUrl?: string;
    IsDefault: boolean;
}

export interface RawFacet {
    AlwaysVisible: boolean;
    CurrencySymbol: string;
    DataType: string;
    DisplayType: string;
    ExpandSelection: boolean;
    FacetId: number;
    FacetType: string;
    Field: string;
    FieldType: string;
    IsCollapsedDefault?: boolean;
    IsCollapsible?: boolean;
    IsCurrency: boolean;
    IsNumeric: boolean;
    IsSearch: boolean;
    IsVisible: boolean;
    Name: string;
    ParamName?: string;
    Ranges?: Array<RawFacetRangeValue>;
    ScrollHeight?: number;
    ScrollThreshold?: number;
    SearchThreshold?: number;
    ShowFacetCount?: boolean;
    ShowItemsCount?: boolean;
    Tooltip?: string;
    TruncateThreshold?: number;
    Values?: Array<RawFacetValue>;
    SwatchData?: Array<RawFacetSwatchValue>;
}

export interface RawMerchandisingItem {
    AltTag?: string;
    BannerId: number;
    CampaignId: number;
    ContentType: string;
    ForwardUrl?: string;
    Height?: number;
    ImageUrl?: string;
    IsMobile: boolean;
    IsTablet: boolean;
    IsTrackingEnabled?: boolean;
    MobileHeight?: number;
    MobileWidth?: number;
    Output?: any;
    TabletHeight?: number;
    TabletWidth?: number;
    Target: string;
    Title: string;
    WidgetType?: string;
    Width?: number;
    Zone: string;
}

export interface RawMerchandising {
    Items: Array<RawMerchandisingItem>;
}

export interface RawPageContent {
    ZoneName: string;
    Items: Array<RawMerchandisingItem>;
}

export interface RawSearchSelectionItem {
    Label: string;
    Value: string;
}

export interface RawSearchSelection {
    Label: string;
    Items: Array<RawSearchSelectionItem>;
}

export interface RawSearchSelections {
    [field: string]: RawSearchSelection;
}

export interface RawSearchPagination {
    CurrentPage: number;
    IsShowFirstLink: boolean;
    IsShowLastLink: boolean;
    MaxPerPage: number;
    NofPages: number;
    NofResults: number;
    NumberOfPageLinks: number;
    Items: Array<{
        Label: string;
        PageSize: number;
        Selected: boolean;
        Default: boolean;
    }>;
}

export interface RawSearchSorting {
    Value?: string;
    Items: Array<{
        Label: string;
        Value: string;
        IsDefault: boolean;
        Selected: boolean;
    }>;
}

export interface RawSearchRedirect {
    Location: string;
    Target?: string;
}

export type RawSearchResultsItemDocument = Record<string, Array<string>> & RawVariantDocument;

export interface RawSearchResultsItem {
    DocId: string;
    Document: RawSearchResultsItemDocument;
    IsPin: boolean;
    IsVisible: boolean;
    Score: number;
}

export interface RawSearchResponse {
    AdjustedKeyword?: string;
    Breadcrumb?: string;
    CustomHtml?: string;
    DidYouMean?: Array<string>;
    Facets?: Array<RawFacet>;
    FeaturedItems?: RawFeaturedItems;
    HeaderTitle?: string;
    Keyword?: string;
    Merchandising?: RawMerchandising;
    MetaDescription?: string;
    MetaKeywords?: string;
    MetaRobots?: string;
    PageContent?: Array<RawPageContent>;
    PageHeading?: string;
    PageLayoutId?: number;
    PageLayoutHtml?: string;
    Pagination?: RawSearchPagination;
    Redirect?: RawSearchRedirect;
    RelCanonical?: string;
    Results?: Array<RawSearchResultsItem>;
    Selections?: RawSearchSelections;
    Sorting?: RawSearchSorting;
    Success: boolean;
    TrackingId: string;
}

export interface RawSearchRequest {
    ClientData?: {
        UserAgent?: string;
        VisitorId?: string;
        VisitId?: string;
    };
    ClientGuid: string;
    CustomURL?: string;
    IgnoreSpellcheck?: boolean;
    IndexName?: string;
    IsInPreview?: boolean;
    Keyword?: string;
    FacetSelections?: Record<string, Array<string> | undefined>;
    MaxPerPage?: number;
    PageNo?: number;
    SearchWithin?: string;
    SortBy?: string;
    Variants?: {
        CountFacetHitOnChild?: boolean;
        MaxPerPage?: number;
        PageNo?: number;
        SortBy?: string;
        SortCode?: string;
    };
}
