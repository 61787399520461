import { AutocompleteResponse } from './autocomplete.models';
import { ItemVariant } from './base.models';
import { ContentType, ContentZone, CustomContent, FeaturedItemsContent, ImageContent, PopularQueriesContent } from './content.models';
import { RecommendationsItem, RecommendationsWidget } from './recommendation.models';
import { Facet, FacetValue, PaginationOption, SearchResponse, SearchResultsItem, SortingOption } from './search.models';
import { AutocompleteItemType } from './tracking.models';

/**
 * @category Components
 */
export interface CalendarDay {
    date: Date;
    value: string;
    currentMonth: boolean;
    enabled: boolean;
    selected: boolean;
}

/**
 * @category Components
 */
export interface CheckboxListFacetValue extends FacetValue {
    visible: boolean;
    toggled: boolean;
    hasChildren: boolean;
    children?: Array<CheckboxListFacetValue>;
}

/**
 * @category Components
 */
export interface ColorFacetValue extends FacetValue {
    visible: boolean;
}

/**
 * @category Components
 */
export type IconName =
    | 'chevron-back'
    | 'chevron-down'
    | 'chevron-forward'
    | 'chevron-left'
    | 'chevron-right'
    | 'chevron-up'
    | 'cross'
    | 'help'
    | 'minus'
    | 'plus'
    | 'star'
    | 'star-empty'
    | 'star-half';

/**
 * @category Components
 */
export interface LinkListFacetValue extends FacetValue {
    visible: boolean;
}

/**
 * @category Components
 */
export interface ModifiedQueryData {
    display: boolean;
    query?: string;
    modifiedQuery?: string;
}

/**
 * @category Components
 */
export interface QuerySuggestionsData {
    display: boolean;
    querySuggestions?: Array<string>;
}

/**
 * @category Components
 */
export interface RangeSliderEventData {
    start: number;
    end: number;
}

/**
 * @category Components
 */
export type SearchResultsMode = 'landing-page' | 'search-results';

/**
 * @category Components
 */
export interface SizeFacetValue extends FacetValue {
    visible: boolean;
}

/**
 * @category Components
 */
export interface BaseComponentModel {}

/**
 * @category Components
 */
export interface AutocompleteComponentModel extends BaseComponentModel, AutocompleteResponse {
    AutocompleteItemType: typeof AutocompleteItemType;
}

/**
 * @category Components
 */
export interface CheckboxListFacetComponentModel extends BaseComponentModel {
    values: Array<CheckboxListFacetValue>;
    excludeEnabled: boolean;
    expanded: boolean;
    displayCount: boolean;
    maxHeight?: number;
    showToggle: boolean;
    strings: {
        collapse: string;
        exclude: string;
        expand: string;
        include: string;
        toggle: string;
    };
}

/**
 * @category Components
 */
export interface ColorFacetComponentModel extends BaseComponentModel {
    values: Array<ColorFacetValue>;
    showToggle: boolean;
    expanded: boolean;
    excludeEnabled: boolean;
    strings: {
        exclude: string;
        include: string;
        toggle: string;
    };
}

/**
 * @category Components
 */
export interface ContentZoneComponentModel extends BaseComponentModel, Omit<ContentZone, 'items'> {
    items: Array<ContentType>;
}

/**
 * @category Components
 */
export interface CustomContentComponentModel extends BaseComponentModel, CustomContent {}

/**
 * @category Components
 */
export interface DatePickerComponentModel extends BaseComponentModel {
    value: string;
    modalVisible: boolean;
    monthSelectorVisible: boolean;
    years: Array<number>;
    months: Array<string>;
    weeks: Array<Array<CalendarDay>>;
    currentYear: number;
    currentMonth: string;
    strings: {
        heading: string;
        label: string;
        next: string;
        previous: string;
        selectMonth: string;
        viewCalendar: string;
    };
}

/**
 * @category Components
 */
export interface DateRangeFacetComponentModel extends BaseComponentModel {
    startValue: string;
    endValue: string;
    minValue: string;
    maxValue: string;
    strings: {
        startDate: string;
        endDate: string;
    };
}

/**
 * @category Components
 */
export interface FacetWrapperComponentModel extends BaseComponentModel, Facet {
    collapsed: boolean;
    filter: string;
    searchable: boolean;
}

/**
 * @category Components
 */
export interface FacetsListComponentModel extends BaseComponentModel {
    facets: Array<Facet>;
    expanded: boolean;
    strings: {
        heading: string;
    };
}

/**
 * @category Components
 */
export interface FeaturedItemsContentComponentModel extends BaseComponentModel, FeaturedItemsContent {}

/**
 * @category Components
 */
export interface FeaturedItemsContentItemComponentModel extends SearchResultsItemComponentModel {
    strings: {
        sale: string;
    };
}

/**
 * @category Components
 */
export interface IconComponentModel extends BaseComponentModel {
    svg: string;
    url: string;
    height: string;
    width: string;
}

/**
 * @category Components
 */
export interface ImageContentComponentModel extends BaseComponentModel, ImageContent {}

/**
 * @category Components
 */
export interface LandingPageComponentModel extends BaseComponentModel, SearchResponse {
    url: string;
}

/**
 * @category Components
 */
export interface LinkListFacetComponentModel extends BaseComponentModel {
    values: Array<LinkListFacetValue>;
    displayCount: boolean;
    expanded: boolean;
    maxHeight?: number;
    showToggle: boolean;
    strings: {
        toggle: string;
    };
}

/**
 * @category Components
 */
export interface ModifiedQueryComponentModel extends BaseComponentModel {
    query: string;
    modifiedQuery: string;
    strings: {
        showingResultsFor: string;
        searchInsteadFor: string;
    };
}

/**
 * @category Components
 */
export interface NumericRangeFacetComponentModel extends BaseComponentModel {
    start: number;
    end: number;
    min: number;
    max: number;
    strings: {
        minimum: string;
        maximum: string;
    };
}

/**
 * @category Components
 */
export interface PageSizeComponentModel extends BaseComponentModel {
    options: Array<PaginationOption>;
}

/**
 * @category Components
 */
export interface PaginationComponentModel extends BaseComponentModel {
    page: number;
    pages: Array<number>;
    firstPage: number;
    lastPage: number;
    firstRecord: number;
    lastRecord: number;
    totalRecords: number;
    totalPages: number;
    maxPageLinks: number;
    displayFirstLink: boolean;
    displayPreviousLink: boolean;
    displayNextLink: boolean;
    displayLastLink: boolean;
    strings: {
        first: string;
        last: string;
        next: string;
        previous: string;
        summary: string;
    };
}

/**
 * @category Components
 */
export interface PopularQueriesContentComponentModel extends BaseComponentModel, PopularQueriesContent {}

/**
 * @category Components
 */
export interface QuerySuggestionsComponentModel extends BaseComponentModel {
    querySuggestions: Array<string>;
}

/**
 * @category Components
 */
export interface RangeSliderComponentModel extends BaseComponentModel {
    start: number;
    end: number;
    strings: {
        start: string;
        end: string;
    };
}

/**
 * @category Components
 */
export interface RangeSliderFacetComponentModel extends BaseComponentModel {
    start: number;
    end: number;
    min: number;
    max: number;
    sliderStart: number;
    sliderEnd: number;
    strings: {
        minimum: string;
        maximum: string;
    };
}

/**
 * @category Components
 */
export interface RatingComponentModel extends BaseComponentModel {
    rating: number;
}

/**
 * @category Components
 */
export interface RecommendationsComponentModel extends BaseComponentModel, RecommendationsWidget {
    buttonsEnabled: boolean;
    carouselPosition: string | undefined;
    headingEnabled: boolean;
    itemWidth: string;
    paginationEnabled: boolean;
    paginationItems: Array<{
        selected: boolean;
        title: string;
    }>;
    strings: {
        next: string;
        previous: string;
    };
}

/**
 * @category Components
 */
export interface RecommendationsItemComponentModel extends BaseComponentModel, RecommendationsItem {
    strings: {
        sale: string;
    };
}

/**
 * @category Components
 */
export interface RecentSearchesFacetComponentModel extends BaseComponentModel {
    values: Array<string>;
}

/**
 * @category Components
 */
export interface RelatedSearchesFacetComponentModel extends BaseComponentModel {
    values: Array<string>;
}

/**
 * @category Components
 */
export interface SearchFieldComponentModel extends BaseComponentModel {
    query?: string;
    strings: {
        placeholder: string;
    };
}

/**
 * @category Components
 */
export interface SearchResultsComponentModel extends BaseComponentModel, SearchResponse {}

/**
 * @category Components
 */
export interface SearchResultsItemComponentModel extends BaseComponentModel, SearchResultsItem {
    strings: {
        sale: string;
    };
}

/**
 * @category Components
 */
export interface SearchResultsListComponentModel extends BaseComponentModel {
    items: Array<SearchResultsItem>;
}

/**
 * @category Components
 */
export interface SearchWithinFacetComponentModel extends BaseComponentModel {
    value: string;
    strings: {
        label: string;
    };
}

/**
 * @category Components
 */
export interface SelectedFacetsComponentModel extends BaseComponentModel {
    selections: Array<{
        field: string;
        title: string;
        selectionTitle: string;
        selectionValue: string;
        excluded: boolean;
    }>;
    strings: {
        remove: string;
    };
}

/**
 * @category Components
 */
export interface SizeFacetComponentModel extends BaseComponentModel {
    values: Array<SizeFacetValue>;
    showToggle: boolean;
    expanded: boolean;
    excludeEnabled: boolean;
    strings: {
        exclude: string;
        include: string;
        toggle: string;
    };
}

/**
 * @category Components
 */
export interface SortingComponentModel extends BaseComponentModel {
    options: Array<SortingOption>;
}

/**
 * @category Components
 */
export interface TabsComponentModel extends BaseComponentModel {
    displayCount: boolean;
    tabs: Array<FacetValue>;
}

/**
 * @category Components
 */
export interface TooltipComponentModel extends BaseComponentModel {
    text: string;
}

/**
 * @category Components
 */
export interface VariantSelectorComponentModel extends BaseComponentModel {
    items: Array<ItemVariant>;
    selectedItem?: ItemVariant;
}
