import { Item, ItemType, ItemVariant } from './base.models';
import { ContentZones } from './content.models';

/**
 * @category Search
 */
export type FacetValueState = {
    toggled?: boolean;
};

/**
 * @category Search
 */
export type FacetState = {
    id: number;
    collapsed?: boolean;
    filter?: string;
    toggled?: boolean;
    values?: Record<string, FacetValueState>;
};

/**
 * @category Search
 */
export type FacetType =
    | 'checkbox'
    | 'color'
    | 'date-range'
    | 'link'
    | 'numeric-range'
    | 'range-slider'
    | 'recent-searches'
    | 'related-searches'
    | 'search'
    | 'size'
    | 'tabs'
    | 'unsupported';

/**
 * @category Search
 */
export interface ContentConfiguration {
    breadcrumbs?: string;
    customHtml?: string;
    heading?: string;
}

/**
 * @category Search
 */
export interface SelectedFacetValue {
    title: string;
    value: string;
    excluded: boolean;
}

/**
 * @category Search
 */
export interface SelectedFacet {
    field: string;
    currency: boolean;
    title: string;
    values: Array<SelectedFacetValue>;
}

/**
 * @category Search
 */
export interface FacetScrolling {
    height?: number;
    threshold: number;
}

/**
 * @category Search
 */
export interface FacetTruncation {
    threshold: number;
}

/**
 * @category Search
 */
export interface FacetSearch {
    threshold: number;
}

/**
 * @category Search
 */
export interface FacetValueColor {
    name: string;
    hex?: string;
    imageUrl?: string;
}

/**
 * @category Search
 */
export interface FacetValue {
    title: string;
    imageUrl?: string;
    value?: string;
    color?: FacetValueColor;
    count?: number;
    level?: number;
    selected?: boolean;
    excluded?: boolean;
    children?: Array<FacetValue>;
}

/**
 * @category Search
 */
interface FacetRange<T> {
    type: string;
    min: T;
    max: T;
    start: T;
    end: T;
}

/**
 * @category Search
 */
export interface DateFacetRange extends FacetRange<Date> {
    type: 'date';
}

/**
 * @category Search
 */
export interface NumericFacetRange extends FacetRange<number> {
    type: 'numeric';
}

/**
 * @category Search
 */
export interface Facet {
    id: number;
    type: FacetType;
    title: string;
    values?: Array<FacetValue>;
    field: string;
    collapsible: boolean;
    collapsed?: boolean;
    displayCount?: boolean;
    range?: DateFacetRange | NumericFacetRange;
    scrolling?: FacetScrolling;
    search?: FacetSearch;
    searchWithin?: string;
    tooltip?: string;
    truncation?: FacetTruncation;
    visible: boolean;
}

/**
 * @category Search
 */
export interface PaginationOption {
    title: string;
    pageSize: number;
    selected: boolean;
    default: boolean;
}

/**
 * @category Search
 */
export interface Pagination {
    page: number;
    pageSize: number;
    totalPages: number;
    totalResults: number;
    maxPageLinks?: number;
    displayFirstLink: boolean;
    displayLastLink: boolean;
    options: Array<PaginationOption>;
}

/**
 * @category Search
 */
export interface Redirect {
    url: string;
    target?: string;
}

/**
 * @category Search
 */
export interface SeoConfiguration {
    canonicalUrl?: string;
    description?: string;
    keywords?: string;
    robots?: string;
    title?: string;
}

/**
 * @category Search
 */
export interface SortingOption {
    title: string;
    value: string;
    default: boolean;
    selected: boolean;
}

/**
 * @category Search
 */
export interface Sorting {
    value?: string;
    options: Array<SortingOption>;
}

/**
 * @category Search
 */
export interface SearchResultsItemVariant extends ItemVariant {}

/**
 * @category Search
 */
export interface SearchResultsItem extends Item {
    pinned: boolean;
    score: number;
    type: ItemType;
}

/**
 * @category Search
 */
export interface SearchResponse {
    content?: ContentConfiguration;
    contentZones?: ContentZones;
    customLayout?: string;
    facets?: Array<Facet>;
    modifiedQuery?: string;
    pagination?: Pagination;
    query?: string;
    querySuggestions?: Array<string>;
    redirect?: Redirect;
    results?: Array<SearchResultsItem>;
    selectedFacets?: Array<SelectedFacet>;
    seo?: SeoConfiguration;
    sorting?: Sorting;
    success: boolean;
    trackingId: string;
}

/**
 * @category Search
 */
export type SelectedFacets = Record<string, Array<string> | undefined>;

/**
 * @category Search
 */
export interface SearchRequest {
    disableSpellcheck?: boolean;
    facets?: SelectedFacets;
    newRequest: boolean;
    pageSize?: number;
    page?: number;
    query?: string;
    searchWithin?: string;
    sort?: string;
    url?: string;
}
