import { RawSearchResultsItem } from './raw-search.models';

export interface RawAutocompleteRequest {
    ClientGuid: string;
    IndexName?: string;
    Keyword?: string;
    DisplayFullResponse?: boolean;
}

export interface RawAutocompleteCategory {
    Url: string;
    Value: string;
    FieldQSName: string;
    FieldQSValue: string;
}

export interface RawAutocompleteContent {
    Value: string;
    Url: string;
    Results: RawSearchResultsItem;
}

export interface RawAutocompleteQuery {
    RawValue: string;
    Url: string;
    Value: string;
}

export interface RawAutocompleteProduct {
    ProductName: string;
    Sku: string;
    Thumb?: {
        AltText: string;
        Url: string;
    };
    Url: string;
    Results: RawSearchResultsItem;
}

export interface RawAutocompleteResponse {
    Categories: Array<RawAutocompleteCategory>;
    CategoryHeading: string;
    Content: Array<RawAutocompleteContent>;
    ContentCount: number;
    ContentHeading: string;
    Count: number;
    DYMContentHeading: string;
    DymContentSearch?: Array<RawAutocompleteQuery>;
    DYMProductHeading: string;
    DymProductsSearch?: Array<RawAutocompleteQuery>;
    Popular: Array<RawAutocompleteQuery>;
    PopularHeading: string;
    ProductHeading: string;
    ProductCount: number;
    Products: Array<RawAutocompleteProduct>;
    ViewAllButtonLabel: string;
}
